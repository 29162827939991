<template>
  <section>
    <b-card>
      <div class="d-flex align-items-center my-2">
        <feather-icon
          icon="SettingsIcon"
          size="18"
        />
        <h4 class="mb-0 ml-50">
          Jackpot Settings:
        </h4>
      </div>
      <b-row>
        <b-col
          cols="3"
          md="2"
          class="d-flex align-items-center"
        >
          <div class="font-weight-bold">
            Percentage to Add
          </div>
        </b-col>
        <b-col cols="2">
          <div>Current</div>
          <b-form-input
            id="type-number"
            :value="currentPercentBetSlip"
            readonly
          />
        </b-col>

        <b-col cols="5">
          <b-row>
            <b-col
              cols="7"
              class="col-deposit"
            >
              <div>Change</div>
              <b-form-input
                id="limit-value"
                v-model="amount"
                :formatter="formatPrice"
              />
            </b-col>

            <b-button
              variant="primary"
              :disabled="loadingLimitBetSlip"
              class="btn-set ml-2 mt-2"
              @click="handleChangePercentBetslip"
            >
              <Spinner v-if="loadingLimitBetSlip" />
              <template v-else>
                Apply
              </template>
            </b-button>
          </b-row>
        </b-col>
      </b-row>

      <b-row>
        <b-col
          cols="3"
          md="2"
          class="d-flex align-items-center"
        >
          <div class="font-weight-bold">
            Minimum Jackpot Prize
          </div>
        </b-col>
        <b-col cols="2">
          <div>Current</div>
          <b-form-input
            id="type-number"
            :value="currentMinimumJackpot"
            readonly
          />
        </b-col>

        <b-col cols="5">
          <b-row>
            <b-col
              cols="7"
              class="col-deposit"
            >
              <div>Change</div>
              <b-form-input
                id="limit-value"
                v-model="amountMinimumJackpot"
                :formatter="formatPrice"
              />
            </b-col>

            <b-button
              variant="primary"
              :disabled="loadingMinimumJackpot"
              class="btn-set ml-2 mt-2"
              @click="handleChangeMinimunJackpot"
            >
              <Spinner v-if="loadingMinimumJackpot" />
              <template v-else>
                Apply
              </template>
            </b-button>
          </b-row>
        </b-col>
      </b-row>

    </b-card>

  </section>
</template>

<script>
import {
  BCol, BRow, BButton, BCard, BFormInput,
} from 'bootstrap-vue'
// import vSelect from 'vue-select'
import { mapActions } from 'vuex'
import {
  formatDate, formatDateTimeAustralia, formatDateQuery, formatCurrencyBuiltInFunc,
} from '@/helpers/utils'
import Spinner from '@core/components/Spinner'
import useToastHandler from '@/services/toastHandler'

export default {
  components: {
    BCol,
    BRow,
    BCard,
    BFormInput,
    BButton,
    Spinner,
  },
  data() {
    return {
      formatDateTimeAustralia,
      formatDate,
      time: '',
      amount: null,
      price: null,

      currentPercentBetSlip: null,
      loadingLimitBetSlip: false,

      currentMinimumJackpot: null,
      loadingMinimumJackpot: false,
      amountMinimumJackpot: null,

    }
  },
  watch: {
  },
  async mounted() {
    const [
      percentBetslip,
      minimumJackpot,
    ] = await Promise.all([
      this.getSetting('percent-of-bet-slip-to-jackpot'),
      this.getSetting('minimum-jackpot-pool'),
    ])

    this.currentPercentBetSlip = percentBetslip
    this.currentMinimumJackpot = minimumJackpot
  },
  methods: {
    ...mapActions({
      getSetting: 'setting/setting/getSetting',
      setNewSetting: 'setting/setting/setNewSetting',
      setLimitNumber: 'setting/limitNumber/setLimitNumber',
    }),

    formatNumberPrize(value) {
      const regexNumber = /^[0-9.]+$/

      if (regexNumber.test(value)) {
        if (value.split('').filter(s => s === '.').length > 1) return
        if (value.includes('.')) {
          const decimalPart = value.split('.')[1]
          const integerPart = value.split('.')[0]

          if (decimalPart.length > 2) {
            return `${integerPart}.${decimalPart.slice(0, 2)}`
          }
        }
        return value
      }
      value = value.replace(/[^0-9.]/, '')
      return value
    },

    async handleChangePercentBetslip() {
      if (!this.amount) return
      this.loadingLimitBetSlip = true
      const res = await this.setNewSetting({ key: 'percent-of-bet-slip-to-jackpot', number: Number(this.amount) })
      if (res) {
        this.currentPercentBetSlip = this.amount
      }
      this.loadingLimitBetSlip = false
      this.amount = null
    },
    async handleChangeMinimunJackpot() {
      if (!this.amountMinimumJackpot) return
      this.loadingMinimumJackpot = true
      const res = await this.setNewSetting({ key: 'minimum-jackpot-pool', number: Number(this.amountMinimumJackpot) })
      if (res) {
        this.currentMinimumJackpot = this.amountMinimumJackpot
      }
      this.loadingMinimumJackpot = false
      this.amountMinimumJackpot = null
    },
    formatNumber(value) {
      let val = value.replace(/[^0-9.]/g, '')
      if (!Number.isInteger(Number(val))) {
        val = ''
      }
      const parts = val.split('.')
      parts[0] = parts[0].replace(/^0+/, '')
      val = parts.join('.')
      const decimalRegex = /^\d*\.?\d*$/
      if (!decimalRegex.test(val)) {
        val = ''
      }
      const numericVal = parseFloat(val)
      // eslint-disable-next-line no-restricted-globals
      if (isNaN(numericVal)) {
        val = ''
      } else if (numericVal < 1) {
        val = '1'
      }
      return val
    },
    formatPrice(value) {
      if (value < 0) return null
      return value
    },
  },
  setup() {
    const { showToastSuccess, showToastError } = useToastHandler()
    return {
      showToastSuccess,
      showToastError,
      formatDateQuery,
      formatCurrencyBuiltInFunc,
    }
  },
}
</script>

  <style lang="scss" scoped>
  .btn-set {
    height: fit-content;
  }
  .add-btn {
    padding: 2px 4px;
  }

  .remove-btn {
    padding: 0px 4px 2px 4px;
    margin-left: 1px;
  }

  .delete-btn {
    color: red;
    font-size: 16px;
    font-weight: 700;
    cursor: pointer;
  }
  </style>

  <style lang="scss">
  @import '@core/scss/vue/libs/vue-flatpicker.scss';
  .v-select .vs__dropdown-toggle,
  .v-select .vs__search {
    cursor: pointer;
  }
  @import '@core/scss/vue/libs/vue-select.scss';

  .ml-14 {
    margin-left: 14px;
  }
  </style>
