<!-- eslint-disable no-undef -->
<template>
  <div>
    <LoadingPage v-if="loading" />

    <b-card v-else>

      <b-tabs
        v-model="tabIndex"
        pills
        lazy
      >
        <!-- Tab: setting -->
        <b-tab>
          <template #title>
            <feather-icon
              icon="SettingsIcon"
              size="16"
              class="mr-0 mr-sm-50"
            />
            <span class="d-none d-sm-inline">Setting</span>
          </template>
          <JackpotSetting
            class="mt-2 pt-75"
          />
        </b-tab>

        <!-- Tab: Current Jackpot -->
        <b-tab>
          <template #title>
            <feather-icon
              icon="UserIcon"
              size="16"
              class="mr-0 mr-sm-50"
            />
            <span class="d-none d-sm-inline">Current Jackpot</span>
          </template>
          <CurrentJackpot
            class="mt-2 pt-75"
          />
        </b-tab>

        <!-- Tab: Past Jackpot -->
        <b-tab>
          <template #title>
            <feather-icon
              icon="DollarSignIcon"
              size="16"
              class="mr-0 mr-sm-50"
            />
            <span class="d-none d-sm-inline">Past Jackpot</span>
          </template>

          <PastJackpot
            class="mt-2 pt-75"
          />
        </b-tab>

      </b-tabs>
    </b-card>
  </div>
</template>

<script>
import { mapActions, mapState } from 'vuex'
import LoadingPage from '@core/components/LoadingPage'
import {
  BCard, BTab, BTabs, VBModal,
} from 'bootstrap-vue'
import useToastHandler from '@/services/toastHandler'
import CurrentJackpot from './CurrentJackpot.vue'
import PastJackpot from './PastJackpot.vue'
import JackpotSetting from './JackpotSetting.vue'

export default {
  components: {
    BTab,
    BTabs,
    BCard,
    LoadingPage,
    CurrentJackpot,
    PastJackpot,
    JackpotSetting,
  },
  directives: {
    'b-modal': VBModal,
  },
  data() {
    return {
      loading: false,
    }
  },
  computed: {
    ...mapState('races/compDetail', ['dataDetail']),
    ...mapState('races/endComp', ['loadingEndComp']),
  },
  async mounted() {
    switch (this.$route.query.tab) {
      case 'past-jackpot':
        this.tabIndex = 1
        break
        //   case 'deposit-history':
        //     this.tabIndex = 2
        //     break

      default:
        break
    }
    this.getLatestJackpot()
  },

  methods: {
    ...mapActions({
      getLatestJackpot: 'races/jackpot/getLatestJackpot',
    }),
  },
  setup() {
    const { showToastSuccess, showToastError } = useToastHandler()

    return {
      showToastSuccess,
      showToastError,
    }
  },
}
</script>

  <style lang="scss" scoped>
  .active-title {
    color: white;
  }

  .text-title {
    color: #000;
  }
  .w-full{
    width: 100%;
    display: block;
  }
  </style>
